body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

input[type="file"] {
  display: none;
}

@font-face {
  font-family: 'TT Commons';
  src: local('TT Commons'), url(./assets/fonts/TT\ Commons/TT\ Commons\ Regular.otf) format('opentype');
}

@font-face {
  font-family: 'TT Commons';
  font-weight: 500;
  src: local('TT Commons'), url(./assets/fonts/TT\ Commons/TT\ Commons\ Medium.otf) format('opentype');
}

@font-face {
  font-family: 'TT Commons';
  font-weight: 600;
  src: local('TT Commons'), url(./assets/fonts/TT\ Commons/TT\ Commons\ DemiBold.otf) format('opentype');
}

@font-face {
  font-family: 'TT Commons';
  font-weight: 700;
  src: local('TT Commons'), url(./assets/fonts/TT\ Commons/TT\ Commons\ Bold.otf) format('opentype');
}

@font-face {
  font-family: 'Montserrat';
  src: local('Montserrat'), url(./assets/fonts/Montserrat/Montserrat-Regular.ttf) format('opentype');
}

@font-face {
  font-family: 'Montserrat';
  font-weight: 500;
  src: local('Montserrat'), url(./assets/fonts/Montserrat/Montserrat-Medium.ttf) format('opentype');
}

@font-face {
  font-family: 'Montserrat';
  font-weight: 600;
  src: local('Montserrat'), url(./assets/fonts/Montserrat/Montserrat-SemiBold.ttf) format('opentype');
}

@font-face {
  font-family: 'Montserrat';
  font-weight: 700;
  src: local('Montserrat'), url(./assets/fonts/Montserrat/Montserrat-Bold.ttf) format('opentype');
}

.modal {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
}

.modal__close {
  display: block;
  position: fixed;
  background: rgba(0, 0, 0, 0.5);
  border: none;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.modal__inner {
  position: relative;
  max-width: 100%;
  padding: 16px 24px;
  border-radius: 20px;
  border: 1px solid rgba(0, 0, 0, 0.1);
  background-color: #ffffff;
  box-shadow: rgba(9, 30, 66, 0.25) 0px 20px 32px -8px;
  margin: auto;
}

.modal__close-btn {
  position: absolute;
  top: 24px;
  right: 24px;
  padding: 0;
  background: transparent;
  border: none;
  cursor: pointer;
}

.modal__close-btn svg {
  width: 58px;
  height: 58px;
}

.modal__close-btn:hover svg {
  opacity: 1;
}

.hideScrollbar::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.hideScrollbar {
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
}